import React, {Component} from 'react';
import * as CONSTANTS from '../../utils/constants';
import OwlCarousel from 'react-owl-carousel-autoheight';
import AwardsResponsiveJSON from "./Awards_Responsive.json";

export default class Awards extends Component {

    render() {
        return (
            <div className="arlo_tm_section" id="awards">
                <div className="arlo_tm_services">
                    <div className="container">
                        <div className="arlo_title_holder">
                            <h3><span>Awards / Fellowships / Scholarships</span></h3>
                        </div>
                        <div className="awards_inner">
                            <ul>
                                <OwlCarousel loop autoplayTimeout={6000} startPosition={1} autoplaySpeed={1500} autoplayHoverPause responsive={AwardsResponsiveJSON} items={1} center className="owl-theme" nav navText={["<i style='width: 75px;' class='arrow-left fas fa-angle-double-left fa-2x'></i>", "<i style='width: 75px;' class='arrow-right fas fa-angle-double-right fa-2x'></i>"]}>
                                    {this.award("James Sites & Regelson Undergraduate Research Scholarship", CONSTANTS.AW3_BULLETPOINTS, true, CONSTANTS.AW3_BUTTONSLIST)}
                                    {this.award("Grace Hopper Conference - Scholar", CONSTANTS.AW1_BULLETPOINTS, false, CONSTANTS.AW1_BUTTONSLIST)}
                                    {this.award("College of Natural Science Dean's List", CONSTANTS.AW2_BULLETPOINTS, false, CONSTANTS.AW2_BUTTONSLIST)}
                                </OwlCarousel>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    award(name, bulletPoints, buttons, buttonsList) {
        return(
            <li className="wow fadeInLeft" data-wow-duration="0.8s">
                <div className="inner">
                    <div className="number">
                        <span>{name}</span>
                    </div>
                    {this.bullets(bulletPoints)}
                    {buttons && <br/>}
                    {buttons && this.buttons(buttonsList)}
                </div>
            </li>
        )
    }

    bullets(bulletPoints) {
        return (
            <div className="title">
                {bulletPoints.map((bullet, index) => {
                    return (
                        <div key={index}><i className="fas fa-chevron-circle-right fa-1x"><div className="exp" style={{fontWeight: 500, marginLeft: "5px"}}>{bullet}</div></i><div></div></div>
                    )
                })}
            </div>
        )
    }

    buttons(buttonsList) {
        return (
            <div className="awardsread_more awardsbuttons" style={{textAlign: "center"}}>
                {buttonsList.map(({e}, index) => {
                    return (
                        <a style={{margin: "3px"}} key={index} href={buttonsList[index][0]} className={buttonsList[index][1]} target="_blank" rel="noreferrer"><span><i className={buttonsList[index][2]}></i> {buttonsList[index][3]}</span></a>
                    )
                })}
            </div>
        )
    }
}

