import React, {Component} from 'react';
import {fontAwesome, iconify} from '../CommonFunctions';
import {Button} from "@material-ui/core";
import {LANGUAGES_STATEMENT} from '../../utils/constants.js';

export default class Languages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            extraLanguagesOpen: false
        };
    }

    render() {
        return(
            <li className="wow fadeInLeft" data-wow-duration="0.8s">
                <div className="inner">
                    <div className="skillheading">
                        <h1><span>Languages</span></h1>
                    </div>
                    <div className="title">
                    </div>
                    <div className="text" style={{textAlign: "center"}}>
                        <div style={{marginBottom: "5%", fontSize: "large"}}>{LANGUAGES_STATEMENT}</div>
                        {fontAwesome("languages fab fa-python fa-4x fa-fw", "Python")}
                        {fontAwesome("languages fab fa-java fa-4x fa-fw", "JAVA")}
                        {fontAwesome("languages fab fa-js-square fa-4x fa-fw", "Java Script")}
                        {this.state.extraLanguagesOpen && this.renderExtraLanguages()}
                        <Button style={{height: "75px", width: "75px", display: "inline-flex", alignItems: "flex-end"}} onClick={() => this.setState(prevState => ({extraLanguagesOpen: !prevState.extraLanguagesOpen}))}>
                                {!this.state.extraLanguagesOpen ? <i className="arrow_bounce fas fa-angle-double-down fa-2x"></i> : <i className="arrow_bounce fas fa-angle-double-up fa-2x"></i>}
                        </Button>
                    </div>
                </div>
            </li>
        )
    }

    renderExtraLanguages() {
        return (
            <div>
                {iconify("simple-icons:typescript", "Type Script")}
                {fontAwesome("languages fab fa-react fa-4x fa-fw", "ReactJS")}
                {fontAwesome("languages fab fa-html5 fa-4x fa-fw", "HTML")}
                {fontAwesome("languages fab fa-css3 fa-4x fa-fw", "CSS")}
                {fontAwesome("languages fab fa-cuttlefish fa-4x fa-fw", "C")}
                {fontAwesome("languages fab fa-cuttlefish fa-4x fa-fw", "C++")}
                {fontAwesome("languages fas fa-hashtag fa-4x fa-fw", "C#")}
                {fontAwesome("languages fas fa-database fa-4x fa-fw", "SQL")}
                {fontAwesome("languages fas fa-bold fa-4x fa-fw", "Bash Script")}
            </div>
        )
    }
}