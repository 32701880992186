import Resume from '../static/documents/Shlok-Gondalia-Resume.pdf'
import Unofficial_Transcript from '../static/documents/Unoffical_Transcript.pdf'
import Research_Report_FA19 from '../static/documents/Research_Report_FA19.pdf'
import Research_Presentation_FA19 from '../static/documents/Research_Presentation_FA19.pdf'
import Research_Presentation_SP20 from '../static/documents/Research_Presentation_SP20.pdf'

//LINKS
export const WEBSITE_LINK = "https://shlokgondalia.com/"
export const LINKEDIN_LINK = "https://www.linkedin.com/in/shlok-gondalia"
export const HANDSHAKE_LINK = "https://colostate.joinhandshake.com/users/15158530"
export const GITHUB_LINK = "https://github.com/shloka-gondalia"
export const FACEBOOK_LINK = "https://www.facebook.com/shloka.gondalia"
export const INSTAGRAM_LINK = "https://www.instagram.com/shlok_gondalia/"

//DOCUMENTS_LINKS
export const RESUME_LINK = Resume;
export const UNOFFICIAL_TRANSCRIPT_LINK = Unofficial_Transcript;
export const RESEARCH_REPORT_FA19_LINK = Research_Report_FA19;
export const RESEARCH_PRESENTATION_FA19_LINK = Research_Presentation_FA19;
export const RESEARCH_PRESENTATION_SP20_LINK = Research_Presentation_SP20;

//ABOUT
export const MAIL_STATEMENT = "shloka.gondalia@gmail.com";
export const GRADUATION_YEAR_STATEMENT = "May 2022";
export const PHONE_STATEMENT = "+1 (970)-481-3790"
export const PHONE_LINK_STATEMENT = "tel:" + PHONE_STATEMENT.replace(/-/g, '').replace(" ", '').replace(/\(/g, '').replace(/\)/g, '');
export const INTERESTS_STATEMENT = "Coding, Playing Video Games (especially competitive), Learning new things about Computers, Watching Movies and Biking.";
export const LOCATION_STATEMENT = "Bellevue, WA-98004";

//SKILLS
export const LANGUAGES_STATEMENT = "The following are all the coding languages that I have interacted with in my Projects or my Job.";
export const TECHNOLOGIES_STATEMENT = "Here are some of the technologies which I have used either for my recent projects or for my job.";
export const TOOLS_STATEMENT = "Below are the tools I have used that I believe allow me to quickly get up to speed on any project.";

//EXPERIENCES

//Experience 1 iconsList, bulletPoints, buttonsList
export const EXP1_ICONSLIST = {
    "React": "expicon fab fa-react fa-2x", 
    "JavaScript": "expicon fab fa-js-square fa-2x", 
    "NodeJS": "expicon fab fa-node-js fa-2x",
    "NPM": "expicon fab fa-npm fa-2x"
};
export const EXP1_BULLETPOINTS = [
    "Make code tutorials for students on CodePen", 
    "Attain students team meetings to help with their daily scrum", 
    "Help students during office hours with difficult topics", 
    "Refine some course content to make it more accessible to students"];
export const EXP1_BUTTONSLIST = [];

//Experience 2 iconsList, bulletPoints, buttonsList
export const EXP2_ICONSLIST = {
    "Python": "expicon fab fa-python fa-2x", 
    "SQL": "expicon fas fa-database fa-2x", 
    "Machine Learning": "expicon fas fa-code fa-2x", 
    "Linux": "expicon fab fa-linux fa-2x", 
    "Git": "expicon fab fa-git-alt fa-2x", 
    "GitHub": "expicon fab fa-github fa-2x", 
    "Plots": "expicon fas fa-chart-pie fa-2x", 
    "Data Processing": "expicon fas fa-database fa-2x", 
};
export const EXP2_BULLETPOINTS = [
    "Work with Fort Collins Energy Department to find premises that show suspicious behaviour", 
    "Use LSTM autoencoder tool to analyze data for anamoly detection", 
    "Use plots & diagrams, along with domain expert to verify the anamolies detected", 
    "Write Python scripts & SQL Queries to get the desired result from the analyzed data"
]
export const EXP2_BUTTONSLIST = [
    ["https://cybersecurity.colostate.edu/", "fas fa-external-link-square-alt fa-1x", "Link to Cybersecurity Center | CSU"],
    ["https://rayscyberlab.org/", "fas fa-external-link-square-alt fa-1x", "Link to Rays Cyber Lab | CSU"]
];

//Experience 3 iconsList, bulletPoints, buttonsList
export const EXP3_ICONSLIST = {
    "Teaching Labs": "expicon fas fa-desktop fa-2x", 
    "C Language": "expicon fab fa-cuttlefish fa-2x", 
    "LC3 (Assembly Language)": "expicon fas fa-microchip fa-2x", 
    "Logic Circuits": "expicon fas fa-ethernet fa-2x", 
    "Grading Servers": "expicon fas fa-server fa-2x", 
    "Tmux": "expicon fas fa-terminal fa-2x", 
    "Linux": "expicon fab fa-linux fa-2x", 
    "Lab Grades": "expicon fas fa-chart-bar fa-2x"
};
export const EXP3_BULLETPOINTS = [
    "Assisted CS270 students with projects and lab activities", 
    "Worked on grading servers, so that there are no issues",
    "Taught one to two labs a week consisting of 20 students",
    "Helped students during office hours with difficult topics"
];
export const EXP3_BUTTONSLIST = [];

//Experience 4 iconsList, bulletPoints, buttonsList
export const EXP4_ICONSLIST = {
    "C#": "expicon fas fa-hashtag fa-2x", 
    "HTML5": "expicon fab fa-html5 fa-2x", 
    "CSS": "expicon fab fa-css3 fa-2x", 
    "Java Script": "expicon fab fa-js-square fa-2x", 
    "SQL": "expicon fas fa-database fa-2x", 
    "Git": "expicon fab fa-git-alt fa-2x", 
    "Bootstrap": "expicon fab fa-bootstrap fa-2x", 
    "Windows 10": "expicon fab fa-windows fa-2x", 
};
export const EXP4_BULLETPOINTS = [
    "Maintaining and updating the website", 
    "Maintaining various online services that are daily used by over 1700 students, faculty & staff", 
    "The frontend uses MojoPortal and Bootstrap", 
    "The backend uses C#, .NET and MS SQL Server"
];
export const EXP4_BUTTONSLIST = [
    ["http://honors.colostate.edu/", "fas fa-external-link-square-alt fa-1x", "Link to Honors Program"]
];

//Experience 5 iconsList, bulletPoints, buttonsList
export const EXP5_ICONSLIST = {
    "Python": "expicon fab fa-python fa-2x", 
    "Machine Learning": "expicon fas fa-code fa-2x", 
    "Tmux": "expicon fas fa-terminal fa-2x", 
    "Linux": "expicon fab fa-linux fa-2x", 
    "Git": "expicon fab fa-git-alt fa-2x", 
    "GitHub": "expicon fab fa-github fa-2x", 
    "Plots": "expicon fas fa-chart-pie fa-2x", 
    "Data Processing": "expicon fas fa-database fa-2x"
};
export const EXP5_BULLETPOINTS = [
    "Work on Data Quality Test Tool under Dr. Sudipto Ghosh & Dr. Indrakshi Ray", 
    "Inspect the quality of data through our tool from five to six organizations to find anomalies", 
    "Upgrade Python code to improve the efficiency of the tool", 
    "Work on the tool that can find faults in data accurately without consulting the client",
    "One of the four authors for the paper published in a IEEE Big Data Conference"
];
export const EXP5_BUTTONSLIST = [
    [Research_Report_FA19, "fas fa-file-pdf fa-1x", "Fall 2019 Report"], 
    [Research_Presentation_FA19, "fas fa-file-pdf fa-1x", "Fall 2019 Presentation"], 
    [Research_Presentation_SP20, "fas fa-file-pdf fa-1x", "Spring 2020 Presentation"]
];
export const RESEARCH_THANKS_NOTE = "Thanks to James Sites & Regelson Undergraduate Research Scholarship/Award for giving me this oppurtunity to be a part of Undergraduate Research Program.     ";

//Experience 6 iconsList, bulletPoints, buttonsList
export const EXP6_ICONSLIST = {
    "Python": "expicon fab fa-python fa-2x", 
    "Machine Learning": "expicon fas fa-code fa-2x",
    "Data Processing": "expicon fas fa-database fa-2x",
    "AWS": "courseicon fab fa-aws fa-2x"
};
export const EXP6_BULLETPOINTS = [
    "Created ML Model focused on security which takes different features into account to detect clusters",
    "Helped to reduce the overall risk with the ML Model by detecting clusters 99% of the time",
    "The initial pilot projected the cost savings of $270k/month for the application team",
    "Convinced the application team to productionalize the model later this year or next year"
];
export const EXP6_BUTTONSLIST = [];

//Experience 7 iconsList, bulletPoints, buttonsList
export const EXP7_ICONSLIST = {
    "Python": "expicon fab fa-python fa-2x", 
    "Machine Learning": "expicon fas fa-code fa-2x",
    "Data Processing": "expicon fas fa-database fa-2x",
    "AWS": "courseicon fab fa-aws fa-2x"
};
export const EXP7_BULLETPOINTS = [
    "Created ML Model which can detect users with high number of HTTP requests send to the app",
    "Added Trending issues clusters feature to one of the existing app which can detect new emerging issues in a date range",
    "This feature will help security engineers prioritize solutions focusing on top risks in Alexa, reducing turnover time by 50%."
];
export const EXP7_BUTTONSLIST = [];

//Experience 8 iconsList, bulletPoints, buttonsList
export const EXP8_ICONSLIST = {
    "JAVA": "courseicon fab fa-java fa-2x", 
    "Java Script": "courseicon fab fa-js-square fa-2x", 
    "React": "courseicon fab fa-react fa-2x",
    "AWS": "courseicon fab fa-aws fa-2x"
};
export const EXP8_BULLETPOINTS = [
    "Worked with the engineering team, PMs, and UI Designers to create a dynamic, React, and Java-based end-to-end Loan and Lease Origination System to streamline solar financing. This system is used by thousands of customers across the continental U.S., handling more than $25 million annually.",
    "Designed and implemented an API Key Management and Authentication system enhancing accessibility and security for third-party client interactions.",
    "Led the end-to-end development of a significant feature (Change Order), from initial requirement gathering with the product team through design and implementation to finishing with a successful production release.",
    "Contributed to the design and rollout of Role-Based Access Control (RBAC) in the Loan and Lease Origination System and Internal Ops Dashboard, benefiting thousands of users.",
    "Contributed to the design and rollout of an OTP-based Authentication system to streamline the user application process, eliminating the need for account creation.",
    "Implemented Launch Darkly across more than 10 microservices to streamline the management of new feature releases.",
    "Optimized code maintainability by 20% by designing and implementing modular schemas, effectively reducing redundancy.",
    "Implemented robust data validation mechanisms for large-scale data models, fortifying security across request payloads."
];
export const EXP8_BUTTONSLIST = [];

// COURSES

export const CURRENT_CLASS_MESSAGE = "Either I am taking this class right now or taking it next Semester. So I can't give access to this class projects."

//CS Courses
export const CS445_ICONSLIST = {
    "Python": "courseicon fab fa-python fa-2x",
}

export const CS415_ICONSLIST = {
    "JAVA": "courseicon fab fa-java fa-2x", 
    "Java Script": "courseicon fab fa-js-square fa-2x", 
    "React": "courseicon fab fa-react fa-2x"
}

export const CS414_ICONSLIST = {
    "JAVA": "courseicon fab fa-java fa-2x", 
    "Java Script": "courseicon fab fa-js-square fa-2x", 
    "React": "courseicon fab fa-react fa-2x", 
    "NPM": "courseicon fab fa-npm fa-2x"
}
export const CS457_ICONSLIST = {
    "Python": "courseicon fab fa-python fa-2x",
    "C++": "courseicon fab fa-cuttlefish fa-2x"
}
export const CS370_ICONSLIST = {
    "C Language": "courseicon fab fa-cuttlefish fa-2x",
    "Python": "courseicon fab fa-python fa-2x",
    "JAVA": "courseicon fab fa-java fa-2x",
    "Docker": "courseicon fab fa-docker fa-2x"
}
export const CS314_ICONSLIST = {
    "JAVA": "courseicon fab fa-java fa-2x", 
    "Java Script": "courseicon fab fa-js-square fa-2x", 
    "React": "courseicon fab fa-react fa-2x", 
    "NPM": "courseicon fab fa-npm fa-2x"
}
export const CS320_ICONSLIST = {
    "Python": "courseicon fab fa-python fa-2x"
}
export const CS356_ICONSLIST = {
    "C++": "courseicon fab fa-cuttlefish fa-2x", 
    "Python": "courseicon fab fa-python fa-2x"
}
export const CS253_ICONSLIST = {
    "C++": "courseicon fab fa-cuttlefish fa-2x", 
    "Bash Script": "courseicon fas fa-bold fa-2x"
}
export const CS270_ICONSLIST = {
    "C Language": "courseicon fab fa-cuttlefish fa-2x", 
    "LC3 (Assembly Language)": "courseicon fas fa-microchip fa-2x", 
    "Logic Circuits": "courseicon fas fa-ethernet fa-2x"
}
export const CS220_ICONSLIST = {
    "Python": "courseicon fab fa-python fa-2x"
}
export const CS165_ICONSLIST = {
    "JAVA": "courseicon fab fa-java fa-2x"
}
export const CS163_ICONSLIST = {
    "JAVA": "courseicon fab fa-java fa-2x"
}

//Other Courses

export const JTC372_ICONSLIST = {}
export const MATH360_ICONSLIST = {}
export const MATH369_ICONSLIST = {"Linear Algebra": "courseicon fas fa-square-root-alt fa-2x"}
export const STAT315_ICONSLIST = {"Stats": "courseicon fas fa-calculator fa-2x", "R": "courseicon fas fa-registered fa-2x"}

//Projects
//Project 1
export const PJ1_ICONSLIST = {
    "React": "expicon fab fa-react fa-2x", 
    "JavaScript": "expicon fab fa-js-square fa-2x", 
    "NodeJS": "expicon fab fa-node-js fa-2x",
    "NPM": "expicon fab fa-npm fa-2x",
    "CSS": "projecticon fab fa-css3 fa-2x",
    "GitHub": "projecticon fab fa-github fa-2x"
};
export const PJ1_PARA = "After taking Software Engineering Class (CS314), I decided to rewrite my website in ReactJS as a personal project. Migrating my website to react was exciting for me, and it also helped me learn new things about different components present in React, like the carousel. This project made my website a lot faster and smaller in size, as I could use conditional rendering, which is hard to do in pure HTML."
export const PJ1_FIRSTBUTTON = [true, "https://shlokgondalia.com/", "fas fa-external-link-square-alt fa-1x", "Personal Website"]
export const PJ1_SECONDBUTTON = [true, "mailto:shlok@rams.colostate.edu", "fab fa-github fa-1x", "Request Access"]

//Project 2
export const PJ2_ICONSLIST = {
    "React": "expicon fab fa-react fa-2x", 
    "JavaScript": "expicon fab fa-js-square fa-2x", 
    "NodeJS": "expicon fab fa-node-js fa-2x",
    "NPM": "expicon fab fa-npm fa-2x",
    "JAVA": "projecticon fab fa-java fa-2x",
    "GitHub": "projecticon fab fa-github fa-2x"
}
export const PJ2_PARA = "We created a mobile responsive, single-page trip planner application with microservices during a semester-long, team-based Agile software development project. In this trip planner, the users can do many things like searching/finding a place, finding the distance between two locations, creating a trip, saving a trip in another format, and optimizing a trip using TSP. In this project, I learned about Project Management, Scrum, Clean Code, Test Driven Development, Testing, Inspections, and Continuous Integration of new features. We used Restful APIS, ReactJS, ReactStrap, Leaflet, JavaScript, JSON, JSON Schemas, SVG, KML, SQL, Concurrency, Slack, GitHub, ZenHub, TravisCI, Code Climate, JUnit, Jest, Maven, NPM, WebPack, and IntelliJ IDEA."
export const PJ2_FIRSTBUTTON = [false, "", "", ""]
export const PJ2_SECONDBUTTON = [true, "", "fas fa-video fa-1x", "Demo Video", true, "Please email me so that I can share the demo video with you!"]

//Project 3
export const PJ3_ICONSLIST = {
    "HTML5": "projecticon fab fa-html5 fa-2x", 
    "CSS": "projecticon fab fa-css3 fa-2x", 
    "Java Script": "projecticon fab fa-js-square fa-2x", 
    "Git": "projecticon fab fa-git-alt fa-2x", 
    "GitHub": "projecticon fab fa-github fa-2x", 
    "Windows 10": "projecticon fab fa-windows fa-2x"
}
export const PJ3_PARA = "After looking at my friend's website, I got motivated to design my own website. For this website, I chose not to use Bootstap, instead I went for JavaScript which I had no idea before creating this website. But, after working on this website, I got to learn a lot about JavaScript and CSS. This is a fully interactive and responsive website developed by using HTML, CSS, JavaScript and Fontawesome."
export const PJ3_FIRSTBUTTON = [true, "https://shloka-gondalia.github.io/Old-Personal-Website/", "fas fa-external-link-square-alt fa-1x", "Old Personal Website"]
export const PJ3_SECONDBUTTON = [true, "mailto:shlok@rams.colostate.edu", "fab fa-github fa-1x", "Request Access"]

//Project 4
export const PJ4_ICONSLIST = {
    "HTML5": "projecticon fab fa-html5 fa-2x", 
    "CSS": "projecticon fab fa-css3 fa-2x", 
    "Bootstrap": "projecticon fab fa-bootstrap fa-2x", 
    "Git": "projecticon fab fa-git-alt fa-2x", 
    "GitHub": "projecticon fab fa-github fa-2x", 
    "Windows 10": "projecticon fab fa-windows fa-2x"
}
export const PJ4_PARA = "I created this website for one of my Honors Seminar assignment over the Fall 2019 semester. This was my first time working with CSS, Bootstrap and developing an actual website. This is a very simple website created by using HTML, CSS and Bootstrap4. The most challenging part was creating the music in GarageBand, but I am not gonna lie, that's was the most fun part of this project."
export const PJ4_FIRSTBUTTON = [true, "https://shloka-gondalia.github.io/HONR-292B/", "fas fa-external-link-square-alt fa-1x", "Project Website"]
export const PJ4_SECONDBUTTON = [true, "mailto:shlok@rams.colostate.edu", "fab fa-github fa-1x", "Request Access"]

//Project 5
export const PJ5_ICONSLIST = {
    "HTML5": "projecticon fab fa-html5 fa-2x", 
    "CSS": "projecticon fab fa-css3 fa-2x", 
    "Java Script": "projecticon fab fa-js-square fa-2x", 
    "Git": "projecticon fab fa-git-alt fa-2x", 
    "GitHub": "projecticon fab fa-github fa-2x", 
    "Windows 10": "projecticon fab fa-windows fa-2x"
}
export const PJ5_PARA = "This is a website I made for my final class project. This website is straightforward. It uses HTML, JavaScript & CSS. It’s mobile responsive."
export const PJ5_FIRSTBUTTON = [true, "https://shloka-gondalia.github.io/jtc372project/", "fas fa-external-link-square-alt fa-1x", "Project Website"]
export const PJ5_SECONDBUTTON = [true, "mailto:shlok@rams.colostate.edu", "fab fa-github fa-1x", "Request Access"]

//Project 6
export const PJ6_ICONSLIST = {
    "React": "expicon fab fa-react fa-2x", 
    "JavaScript": "expicon fab fa-js-square fa-2x", 
    "NodeJS": "expicon fab fa-node-js fa-2x",
    "NPM": "expicon fab fa-npm fa-2x",
    "JAVA": "projecticon fab fa-java fa-2x",
    "GitHub": "projecticon fab fa-github fa-2x"
}
export const PJ6_PARA = "We create a mobile responsive, single-page Multiplayer Chess game with microservices during a semester-long, team-based Agile software development project. The entire project was based on Object-Oriented. Therefore all the logic was written in JAVA which resided in the backend. In this Chess Game, the users can create their account and log in to play the game with other users. Passwords were hashed before storing, and the entire system ran on Authentication Token. Users can create their own game and make it Private, which only allows invited players to join in the first-come, first-serve manner or make it Public where any other player can join that game without any invites. Users can invite multiple people at a single time. We also create a Leaderboard where users can see where they rank compared to other users registered in our system. We used Restful APIS, ReactJS-Hooks, ReactStrap, MaterialUI, JavaScript, JSON, JSON Schemas, Slack, GitHub, ZenHub, Github Actions, Code Climate, JUnit, Jest, Maven, NPM, WebPack, and IntelliJ IDEA."
export const PJ6_FIRSTBUTTON = [false, "", "", ""]
export const PJ6_SECONDBUTTON = [true, "", "fas fa-video fa-1x", "Demo Video", true, "Please email me so that I can share the demo video with you!"]

//Awards
export const AW1_BULLETPOINTS = ["2021", "2020"];
export const AW1_BUTTONSLIST = [];

export const AW2_BULLETPOINTS = ["Spring 2022 Semester", "Fall 2021 Semester", "Spring 2021 Semester", "Fall 2020 Semester", "Spring 2020 Semester", "Fall 2019 Semester", "Spring 2019 Semester", "Fall 2018 Semester"];
export const AW2_BUTTONSLIST = [];

export const AW3_BULLETPOINTS = ["Spring 2021 Semester", "Fall 2020 Semester", "Spring 2020 Semester", "Fall 2019 Semester"];
export const AW3_BUTTONSLIST = [ 
    ["https://compsci.colostate.edu/scholarships-fellowships/#fancy-title-24", "", "fas fa-external-link-square-alt", "Award Description"]
];