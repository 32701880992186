import React, {Component} from 'react';
import WrapperAll from './WrapperAll';

export default class App extends Component {
    render() {
        return (
            <div>
                {/* <div className="arlo_tm_preloader">
                    <div className="spinner_wrap">
                        <div className="spinner"></div>
                    </div>
                </div> */}
                <WrapperAll/>
            </div>
        )
    }
}