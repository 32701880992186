import React, {Component} from 'react';
import {alertFunction} from '../CommonFunctions'
import * as CONSTANTS from '../../utils/constants';
import OwlCarousel from 'react-owl-carousel-autoheight';
import CoursesResponsiveJSON from "./Courses_Responsive.json";

export default class Courses extends Component {

    render() {
        return (
            <div className="arlo_tm_section" id="courses">
                <div className="arlo_tm_services">
                    <div className="container">
                        <div className="arlo_title_holder">
                            <h3><span>Courses Taken</span></h3>
                        </div>
                        {this.renderCS()}
                        {this.renderOther()}
                    </div>
                </div>
            </div>	
        )
    }

    renderCS() {
        return (
            <div>
                <div className="arlo_title_holder">
                    <h4><span>CS Courses</span></h4>
                </div>
                <div className="course_inner">
                    <ul>
                        <OwlCarousel autoplaySpeed={1500} autoplay autoplayHoverPause responsive={CoursesResponsiveJSON} items={1} loop center className="owl-theme" nav navText={["<i style='width: 75px;' class='arrow-left fas fa-angle-double-left fa-2x'></i>", "<i style='width: 75px;' class='arrow-right fas fa-angle-double-right fa-2x'></i>"]}>
                            {this.course("CS 445", "Introduction to Machine Learning", "Spring 2022 Semester", CONSTANTS.CS445_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20445", false, "Request Access to All CS 445 Projects", "", "fab fa-github fa-1x", true)}
                            {this.course("CS 415", "Software Testing", "Spring 2022 Semester", CONSTANTS.CS415_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20415", false, "Request Access to All CS 415 Projects", "", "fab fa-github fa-1x", true)}
                            {this.course("CS 414", "Object-Oriented Design", "Fall 2021 Semester", CONSTANTS.CS414_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20414", true, "Project Demo Video", "Please email me so that I can share the demo video with you!", "fas fa-video fa-1x", true)}
                            {this.course("CS 457", "Computer Networks and the Internet", "Fall 2021 Semester", CONSTANTS.CS457_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20457", false, "Request Access to All CS 457 Projects", "", "fab fa-github fa-1x", true)}
                            {this.course("CS 370", "Operating Systems", "Spring 2021 Semester", CONSTANTS.CS370_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20370", false, "Request Access to All CS 370 Projects", "", "fab fa-github fa-1x", true)}
                            {this.course("CS 314", "Software Engineering", "Fall 2020 Semester", CONSTANTS.CS314_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20314", true, "Project Demo Video", "Please email me so that I can share the demo video with you!", "fas fa-video fa-1x", true)}
                            {this.course("CS 320", "Algorithms Theory and Practice", "Fall 2020 Semester", CONSTANTS.CS320_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20320", false, "Request Access to All CS 320 Projects", "", "fab fa-github fa-1x", true)}
                            {this.course("CS 356", "System Security", "Fall 2020 Semester", CONSTANTS.CS356_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20356", false, "Request Access to All CS 356 Projects", "", "fab fa-github fa-1x", true)}
                            {this.course("CS 253", "Software Development with C++", "Spring 2020 Semester", CONSTANTS.CS253_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20253", false, "Request Access to All CS 253 Projects", "", "fab fa-github fa-1x", true)}
                            {this.course("CS 270", "Computer Organization", "Fall 2019 Semester", CONSTANTS.CS270_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20270", false, "Request Access to All CS 270 Projects", "", "fab fa-github fa-1x", true)}
                            {this.course("CS 220", "Discrete Structures and their Applications", "Fall 2019 Semester", CONSTANTS.CS220_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20220", false, "Request Access to All CS 220 Projects", "", "fab fa-github fa-1x", true)}
                            {this.course("CS 165", "CS2 -- Data Structures", "Spring 2019 Semester", CONSTANTS.CS165_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20165", false, "Request Access to All CS 165 Projects", "", "fab fa-github fa-1x", true)}
                            {this.course("CS 163", "CS1 -- No Prior Programming Experience", "Fall 2018 Semester", CONSTANTS.CS163_ICONSLIST, "https://catalog.colostate.edu/search/?P=CS%20163", false, "Request Access to All CS 163 Projects", "", "fab fa-github fa-1x", true)}
                        </OwlCarousel>
                    </ul>
                </div>
            </div>
        )
    }

    renderOther() {
        return (
            <div>
                <div className="arlo_title_holder">
                    <h4><span>Other Courses</span></h4>
                </div>
                <div className="course_inner">
                    <ul>
                        <OwlCarousel autoplaySpeed={1500} autoplay autoplayHoverPause responsive={CoursesResponsiveJSON} items={1} loop center className="owl-theme" nav navText={["<i style='width: 75px;' class='arrow-left fas fa-angle-double-left fa-2x'></i>", "<i style='width: 75px;' class='arrow-right fas fa-angle-double-right fa-2x'></i>"]}>
                            {this.course("MATH 360", "Mathematics of Information Security", "Spring 2022 Semester", {}, "https://catalog.colostate.edu/search/?P=MATH%20360", false, "", "", "")}
                            {this.course("JTC 372", "Web Design and Management", "Spring 2021 Semester", {}, "https://catalog.colostate.edu/search/?P=JTC%20372", false, "", "", "")}
                            {this.course("MATH 340", "Intro to Ordinary Differential Equations", "Spring 2021 Semester", {}, "https://catalog.colostate.edu/search/?P=MATH%20340", false, "", "", "")}
                            {this.course("MATH 369", "Linear Algebra I", "Spring 2020 Semester", {}, "https://catalog.colostate.edu/search/?P=MATH%20369", false, "", "", "")}
                            {this.course("STAT 315", "Statistics for Engineers and Scientists", "Spring 2020 Semester", {}, "https://catalog.colostate.edu/search/?P=STAT%20315", false, "", "", "")}
                        </OwlCarousel>
                    </ul>
                </div>
            </div>
        )
    }

    course(courseName, title1, title2, iconsList, href, alert, buttonTitle, alertMessage, className, button = false) {
        return (
            <li className="wow fadeInLeft" data-wow-duration="0.8s">
                <div className="inner">
                    <div className="number">
                        <span>{courseName}</span>
                    </div>
                    <div className="title">
                        <h5>{title1}</h5>
                        <span>{title2}</span>
                    </div>
                    {this.icons(iconsList)}
                    <div className="read_more coursebuttons" style={{textAlign: "center"}}>
                        <a href={href} target="_blank" rel="noreferrer"><span><i className="fas fa-external-link-square-alt fa-1x"></i> Course Description</span></a>
                    </div>
                    {button && this.button(alert, buttonTitle, alertMessage, className)}
                </div>
            </li>
        )
    }

    icons(iconsList) {
        let iconsListKeys = Array.from(Object.keys(iconsList));
        return (
            <div className="text">
                {iconsListKeys.map((icon, index) => {
                    return (
                        <i key={index} className={iconsList[icon]} title={icon} style={{marginRight: "20px", marginBottom: "10px", color: "white", width: "40px", textAlign: "center", borderRadius: "5px", verticalAlign: "middle", lineHeight: "40px"}}></i>
                    )
                })}
            </div>
        )
    }

    button(alert, buttonTitle, alertMessage, className){
        if(alert){
            return (
                <div className="gitread_more gitbuttons" style={{textAlign: "center"}}>
                    <a href="/#" onClick={(e) => {e.preventDefault(); alertFunction(alertMessage)}}><span><i className={className}></i> {buttonTitle}</span></a>
                </div>
            )
        }
        else{
            return (
                <div className="gitread_more gitbuttons" style={{textAlign: "center"}}>
                    <a href="mailto:shlok@rams.colostate.edu" target="_blank" rel="noreferrer"><span><i className={className}></i> {buttonTitle}</span></a>
                </div>
            )
        }
    }
}

