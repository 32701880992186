import React, {Component} from 'react';
import {fontAwesome, iconify} from '../CommonFunctions'
import {Button} from "@material-ui/core";
import {TOOLS_STATEMENT} from '../../utils/constants.js';

export default class Tools extends Component {

    constructor(props) {
        super(props);
        this.state = {
            extraToolsOpen: false
        };
    }

    render() {
        return(
            <li className="wow fadeInLeft" data-wow-duration="0.8s">
                <div className="inner">
                    <div className="skillheading">
                        <h1><span>Tools</span></h1>
                    </div>
                    <div className="title">
                    </div>
                    <div className="text" style={{textAlign: "center"}}>
                        <div style={{marginBottom: "5%", fontSize: "large"}}>{TOOLS_STATEMENT}</div>
                        {fontAwesome("languages fas fa-code-branch fa-4x fa-fw", "Git")}
                        {iconify("simple-icons:jira", "Jira")}
                        {iconify("simple-icons:postman", "Postman")}
                        {this.state.extraToolsOpen && this.renderExtraTools()}
                        <Button style={{height: "75px", width: "75px", display: "inline-flex", alignItems: "flex-end"}} onClick={() => this.setState(prevState => ({extraToolsOpen: !prevState.extraToolsOpen}))}>
                                {!this.state.extraToolsOpen ? <i className="arrow_bounce fas fa-angle-double-down fa-2x"></i> : <i className="arrow_bounce fas fa-angle-double-up fa-2x"></i>}
                        </Button>
                    </div>
                </div>
            </li>
        )
    }

    renderExtraTools() {
        return (
            <div>
                {fontAwesome("languages fab fa-github fa-4x fa-fw", "GitHub")}
                {iconify("simple-icons:datadog", "Datadog")}
                {iconify("logos:launchdarkly-icon", "Launch Darkly")}
                {fontAwesome("languages fab fa-npm fa-4x fa-fw", "NPM")}
                {iconify("cib:travisci", "Travis CI")}
                {iconify("cib:code-climate", "Code Climate")}
                {iconify("logos:zenhub-icon", "ZenHub")}
                {iconify("cib:eclipseide", "Eclipse")}
                {iconify("akar-icons:vscode-fill", "VSCode")}
                {iconify("devicon-plain:intellij", "IntelliJ Idea")}
                {iconify("devicon-plain:clion", "CLion")}
                {iconify("devicon-plain:pycharm", "PyCharm")}
                {iconify("devicon-plain:jupyter-wordmark", "Jupyter")}
                {iconify("simple-icons:wireshark", "Wireshark")}
                {fontAwesome("languages fab fa-slack fa-4x fa-fw", "Slack")}
                {iconify("bx:bxl-microsoft-teams", "Microsoft Teams")}
            </div>
        )
    }
}