import React, {Component} from 'react';
import TopBar from './TopBar';
import LeftPart from './LeftPart';
import RightPart from './RightPart';

export default class WrapperAll extends Component {

    render() {
        return(
            <div className="arlo_tm_all_wrap">
		        <div className="wrapper_inner">
                    <TopBar/>
                    <LeftPart/>
                    <RightPart/>
                </div>
            </div>
        )
    }

}