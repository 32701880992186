import React, {Component} from 'react';

export default class Footer extends Component {
    render() {
        return (
            <div className="arlo_tm_section">
                <div className="arlo_tm_footer">
                    <div className="container">
                        <div className="footer_inner">
                            <div className="copyright wow fadeInLeft" data-wow-duration="0.8s">
                                <p>Website Designed by Shlok Gondalia</p>
                                <p>Copyright <i className="far fa-copyright"></i> 2024 Shlok Gondalia. Code released under the <a href="https://github.com/shloka-gondalia/Personal-Website/blob/master/LICENSE" target="_blank" rel="noreferrer">MIT</a> license.</p>
                                <p>Last Updated: 24th July, 2024</p>
                            </div>
                            <div className="top wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                <span></span>
                                <a href="/#"><span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

