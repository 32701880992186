import React, {Component} from 'react';
import {alertFunction} from '../CommonFunctions';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";


export default class ProjectModal extends Component {
    render() {
        return (
            <Modal isOpen={this.props.isInfoOpen} toggle={() => this.props.toggleInfoOpen()} autoFocus={false} returnFocusAfterClose={false}>
                <ModalHeader toggle={() => this.props.toggleInfoOpen()}>
                    {this.props.projects[this.props.index][0]}
                </ModalHeader>
                <ModalBody>
                    <div style={{textAlign: "center"}}>
                        {this.icons(this.props.projects[this.props.index][3])}
                    </div>
                    <div style={{textAlign: "justify"}}>
                        {this.props.projects[this.props.index][4]}
                    </div>
                    <ModalFooter>
                        {this.props.projects[this.props.index][5][0] && this.firstButton(this.props.projects[this.props.index][5])}
                        {this.props.projects[this.props.index][6][0] && this.secondButton(this.props.projects[this.props.index][6])}
                    </ModalFooter>
                </ModalBody>
            </Modal>
        )
    }

    icons(iconsList) {
        let iconsListKeys = Array.from(Object.keys(iconsList));
        return (
            <div className="text">
                {iconsListKeys.map((icon, index) => {
                    return (
                        <i key={index} className={iconsList[icon]} title={icon} style={{marginRight: "20px", marginBottom: "10px", color: "white", width: "40px", textAlign: "center", borderRadius: "5px", verticalAlign: "middle", lineHeight: "40px"}}></i>
                    )
                })}
            </div>
        )
    }

    firstButton(firstButton) {
        return (
            <div className="project_read_more projectbuttons" style={{textAlign: "center"}}>
                <a href={firstButton[1]} target="_blank" rel="noreferrer"><span><i className={firstButton[2]}></i> {firstButton[3]}</span></a>
            </div>
        )
    }

    secondButton(secondButton) {
        if(secondButton[4]){
            return (
                <div className="gitpread_more gitpbuttons" style={{textAlign: "center"}}>
                    <a href={secondButton[1]} onClick={(e) => {e.preventDefault(); alertFunction(secondButton[5])}}><span><i className={secondButton[2]}></i> {secondButton[3]}</span></a>
                </div>
            )
        }
        else {
            return (
                <div className="gitpread_more gitpbuttons" style={{textAlign: "center"}}>
                    <a href={secondButton[1]} target="_blank" rel="noreferrer"><span><i className={secondButton[2]}></i> {secondButton[3]}</span></a>
                </div>
            )
        }
    }
}