import React, {Component} from 'react';
import Home from './RightPart/Home';
import About from './RightPart/About';
import Skills from './RightPart/Skills';
import Experiences from './RightPart/Experiences';
import Courses from './RightPart/Courses';
import Projects from './RightPart/Projects';
import GPA from './RightPart/GPA';
import Awards from './RightPart/Awards';
import Stats from './RightPart/Stats';
import Contact from './RightPart/Contact';
import Footer from './RightPart/Footer'; 

export default class RightPart extends Component {
    render() {
        return (
            <div className="arlo_tm_rightpart">
				<div className="rightpart_inner">
                    <Home/>
                    <About/>
                    <Skills/>
                    <Experiences/>
                    <Courses/>
                    <Projects/>
                    <GPA/>
                    <Awards/>
                    <Stats/>
                    <Contact/>
                    <Footer/>
                </div>
            </div>
        )
    }
}

