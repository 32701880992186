import React, {Component} from 'react';

export default class Stats extends Component {
    render() {
        return (
            <div className="arlo_tm_section" id="stats">
                <div className="arlo_tm_services">
                    <div className="container">
                        <div className="arlo_title_holder">
                            <h3><span>Interesting Stats About This Website</span></h3>
                        </div>
                        <div className="stats_inner">
                            <ul>
                                {this.stat("Hours Spent", "98", "2")}
                                {this.stat("Lines of Code", "3395", "20")}
                                {this.stat("No. of Keystrokes", "31230", "175")}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>	
        )
    }

    stat(title, dataMax, dataIncrement) {
        return (
            <li className="wow fadeInLeft" data-wow-duration="0.8s">
                <div className="inner">
                    <div className="number">
                        <span>{title}</span>
                    </div>
                    <div className="title" style={{textAlign: "center", color: "#f8bc2a", textShadow: "0 0 20px #424141"}}>
                        <div className="numscroller code-count-value" data-min='0' data-max={dataMax} data-delay='3' data-increment={dataIncrement} style={{fontSize: "55px", fontWeight: 700}}></div>
                    </div>
                </div>
            </li>
        )
    }
}

