import React from 'react';
import { Icon } from '@iconify/react';

export function fontAwesome(className, title) {
    return (
        <i className={className} style={{marginRight: "2%", marginLeft: "2%", marginTop: "5%"}}>
            <div style={{fontSize: "medium", fontWeight: "100", marginTop: "20%"}}>{title}</div>
        </i>
    )
}

export function iconify(icon, title) {
    return (
        <i class="languages fab" style={{marginRight: "2%", marginLeft: "2%", marginTop: "5%", minWidth: "80px", maxWidth: "80px", minHeight: "96px"}}>
            <Icon icon={icon} className="big-icon"/>
            <div style={{fontSize: "medium", fontWeight: "100", marginTop: "20%"}}>{title}</div>
        </i>
    )
}

export function mail(mail){
    return "mailto:" + mail;
}

export function alertFunction(message) {
    alert(message);
    return false;
}