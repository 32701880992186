import React, {Component} from 'react';
import {mail} from '../CommonFunctions'
import {WEBSITE_LINK, LINKEDIN_LINK, MAIL_STATEMENT, PHONE_STATEMENT, PHONE_LINK_STATEMENT, LOCATION_STATEMENT, HANDSHAKE_LINK, FACEBOOK_LINK, INSTAGRAM_LINK} from '../../utils/constants.js';


export default class Contacts extends Component {
    render() {
        return (
            <div className="arlo_tm_section" id="contact">
                <div className="arlo_tm_contact_wrapper_all">
                    <div className="arlo_tm_contact">
                        <div className="container">
                            <div className="arlo_title_holder">
                                <h3>Get in <span>Touch</span></h3>
                            </div>
                            <div className="contact_inner">
                                <div className="left wow fadeInLeft" data-wow-duration="0.8s">
                                    <div className="about_short_contact_wrap">
                                        <ul>
                                            {this.contact("fas fa-link", "Website:", WEBSITE_LINK, "Shlok Gondalia")}
                                            {this.contact("fab fa-linkedin-in", "Linkedin:", LINKEDIN_LINK, "shlok-gondalia")}
                                            {this.contact("fas fa-handshake", "Handshake:", HANDSHAKE_LINK, "Shlok Gondalia")}
                                            {this.contact("fas fa-envelope", "Mail:", mail(MAIL_STATEMENT), MAIL_STATEMENT)}
                                            {this.contact("fas fa-mobile", "Phone:", PHONE_LINK_STATEMENT, PHONE_STATEMENT)}
                                            {this.contact("fab fa-facebook-f", "Facebook:", FACEBOOK_LINK, "@shloka.gondalia")}
                                            {this.contact("fab fa-instagram", "Instagram:", INSTAGRAM_LINK, "@shlok_gondalia")}
                                            {this.location("fas fa-map-marked-alt", "Location:", LOCATION_STATEMENT)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    contact(className, label, href, title) {
        return (
            <li>
                <i className={className} style={{minWidth: "25px"}}></i>
                <span><label>{label}</label><a href={href} target="_blank" rel="noreferrer">{title}</a></span>
            </li>
        )
    }

    location(className, label, title) {
        return (
            <li>
                <i className={className} style={{minWidth: "25px"}}></i>
                <span><label>{label}</label><a className="href_location" href="/#">{title}</a></span>
            </li>
        )
    }
}

