import React, {Component} from 'react';
import {UNOFFICIAL_TRANSCRIPT_LINK} from '../../utils/constants.js';

export default class GPA extends Component {
    render() {
        return (
            <div className="arlo_tm_section" id="gpa">
                <div className="arlo_tm_services">
                    <div className="container">
                        <div className="arlo_title_holder">
                            <h3><span>GPA</span></h3>
                        </div>
                        
                        <div className="arlo_tm_progress_wrap">
                            <div className="container">
                                <div className="progress_wrap_inner">
                                    <div className="right wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                        <div className="gpaButtons" style={{textAlign: "center", marginBottom: "50px"}}>
                                            <a href={UNOFFICIAL_TRANSCRIPT_LINK} target="_blank" rel="noreferrer"><span><i className="fas fa-download fa-1x"></i> Unofficial Transcript</span></a>
                                        </div>
                                        {this.renderProgressBar()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderProgressBar() {
        return (
            <div className="arlo_progress">
                {this.progressBar("100", "#297ac5", "Cumulative GPA", "4.0/4.0")}
                <p><br/></p>
                {this.progressBar("100", "#29c565", "Credit Hours", "122/122")}
                <p><br/></p>
                {this.progressBar("100", "#ff4b36", "Spring 2022 - GPA", "4.0/4.0")}
                {this.progressBar("100", "#ff4b36", "Fall 2021 - GPA", "4.0/4.0")}
                {this.progressBar("100", "#ff4b36", "Spring 2021 - GPA", "4.0/4.0")}
                {this.progressBar("100", "#ff4b36", "Fall 2020 - GPA", "4.0/4.0")}
                {this.progressBar("100", "#ff4b36", "Spring 2020 - GPA", "4.0/4.0")}
                {this.progressBar("100", "#ff4b36", "Fall 2019 - GPA", "4.0/4.0")}
                {this.progressBar("100", "#ff4b36", "Spring 2019 - GPA", "4.0/4.0")}
                {this.progressBar("100", "#ff4b36", "Fall 2018 - GPA", "4.0/4.0")}
            </div>
        )
    }

    progressBar(dataValue, dataColor, label, number) {
        return (
            <div className="progress_inner" data-value={dataValue} data-color={dataColor}>
                <span><span className="label">{label}</span><span className="number">{number}</span></span>
                <div className="background"><div className="bar"><div className="bar_in"></div></div></div>
            </div>
        )
    }
}

