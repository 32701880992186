import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./static/styles/arlo.css"
import "./static/styles/plugins.css"
import "./static/styles/prism.css"
import "./static/styles/fonts/css/all.css"
import "./static/styles/owl.carousel.css"
import "./static/styles/owl.theme.default.css"

ReactDOM.render(<App />,document.getElementById('root'));
