import React, {Component} from 'react';
import * as CONSTANTS from '../../utils/constants';
import OwlCarousel from 'react-owl-carousel-autoheight';

export default class Experiences extends Component {

    render() {
        return (
            <div className="arlo_tm_section" id="experiences">
                <div className="arlo_tm_services">
                    <div className="container">
                        <div className="arlo_title_holder">
                            <h3><span>Experiences</span></h3>
                        </div>
                        <div className="experience_inner">
                            <ul>
                                <OwlCarousel autoplaySpeed={1500} autoplay autoplayHoverPause loop items={1} center className="owl-theme" nav navText={["<i style='width: 75px;' class='arrow-left fas fa-angle-double-left fa-2x'></i>", "<i style='width: 75px;' class='arrow-right fas fa-angle-double-right fa-2x'></i>"]}>
                                    {this.experince("Software Development Engineer | SunPower (April 2023 - Present)", "SunPower - Bellevue, Washington", "", CONSTANTS.EXP8_ICONSLIST, CONSTANTS.EXP8_BULLETPOINTS, CONSTANTS.EXP8_BUTTONSLIST)}  
                                    {this.experince("Software Engineer I | Amazon (July 2022 - April 2023)", "Amazon - Bellevue, Washington", "", CONSTANTS.EXP7_ICONSLIST, CONSTANTS.EXP7_BULLETPOINTS, CONSTANTS.EXP7_BUTTONSLIST)}
                                    {this.experince("Software Engineer I Intern | Amazon (May 2021 - Aug 2021)", "Amazon - Bellevue, Washington", "", CONSTANTS.EXP6_ICONSLIST, CONSTANTS.EXP6_BULLETPOINTS, CONSTANTS.EXP6_BUTTONSLIST)}
                                    {this.experince("Full Stack Software Engineer (September 2019 - May 2022)", "Honors Program - Colorado State University", "", CONSTANTS.EXP4_ICONSLIST, CONSTANTS.EXP4_BULLETPOINTS, CONSTANTS.EXP4_BUTTONSLIST)}
                                    {this.experince("Research Assistant (August 2019 - January 2022)", "Computer Science Dept. - Colorado State University", "", CONSTANTS.EXP5_ICONSLIST, CONSTANTS.EXP5_BULLETPOINTS, CONSTANTS.EXP5_BUTTONSLIST)}
                                    {this.experince("Cyber Security Intern (May 2020 - May 2021)", "Cybersecurity Center - Colorado State University", "", CONSTANTS.EXP2_ICONSLIST, CONSTANTS.EXP2_BULLETPOINTS, CONSTANTS.EXP2_BUTTONSLIST)}
                                    {this.experince("Undergraduate Teaching Assistant (January - MAY 2021) & (January - MAY 2022)", "Class: CS314 (Software Engineering)", "Computer Science Dept. - Colorado State University", CONSTANTS.EXP1_ICONSLIST, CONSTANTS.EXP1_BULLETPOINTS, CONSTANTS.EXP1_BUTTONSLIST)}
                                    {this.experince("Undergraduate Teaching Assistant (January 2020 - December 2020)", "Class: CS270 (Computer Organization)", "Computer Science Dept. - Colorado State University", CONSTANTS.EXP3_ICONSLIST, CONSTANTS.EXP3_BULLETPOINTS, CONSTANTS.EXP3_BUTTONSLIST)}
                                </OwlCarousel>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    experince(title, employer1, employer2, iconsList, bulletPoints, buttonsList, id = false, idName = "") {
        let iconsListKeys = Array.from(Object.keys(iconsList));
        return (
            <li className="wow fadeInLeft" data-wow-duration="0.8s">
                <div className="inner">
                    {this.title(title, id, idName)}
                    <div className="title">
                        <h6>{employer1}</h6>
                        {employer2 !== "" && <h6>{employer2}</h6>}
                    </div>
                    <div className="text">
                        {this.icons(iconsList, iconsListKeys)}
                        <div></div>
                        {this.bullets(bulletPoints)}
                    </div>
                    {this.buttons(buttonsList)}
                </div>
            </li>
        )
    }

    title(title, id, idName) {
        if(id){
            return ( <div className="number" id={idName}><span>{title}</span></div> )
        }
        else{
            return ( <div className="number"><span>{title}</span></div> )
        }
    }

    icons(iconsList, iconsListKeys) {
        return (
            iconsListKeys.map((icon, index) => {
                return (
                    <i key={index} className={iconsList[icon]} title={icon} style={{marginRight: "20px", marginBottom: "10px", color: "white", width: "40px", textAlign: "center", borderRadius: "5px", verticalAlign: "middle", lineHeight: "40px"}}></i>
                )
            })
        )
    }

    bullets(bulletPoints) {
        return (
            bulletPoints.map((bullet, index) => {
                return (
                    <div key={index}><i className="fas fa-chevron-circle-right fa-1x"><div className="exp" style={{fontWeight: 500, marginLeft: "5px"}}>{bullet}</div></i><div></div></div>
                )
            })
        )
    }

    buttons(buttonsList) {
        return (
            <div className="read_more experience_buttons" style={{textAlign: "center"}}>
                {buttonsList.map(({e}, index) => {
                    return (
                        <a style={{margin: "3px"}} key={index} href={buttonsList[index][0]} target="_blank" rel="noreferrer"><span><i className={buttonsList[index][1]}></i> {buttonsList[index][2]}</span></a>
                    )
                })}
            </div>
        )
    }

    
}

