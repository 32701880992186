import React, {Component} from 'react';
import Black_550_650 from '../../static/images/550x650.jpg';
import About_Me from '../../static/images/About_Me.jpg'
import {mail} from '../CommonFunctions'
import {RESUME_LINK, LINKEDIN_LINK, GITHUB_LINK, MAIL_STATEMENT, GRADUATION_YEAR_STATEMENT, PHONE_STATEMENT, PHONE_LINK_STATEMENT, INTERESTS_STATEMENT, LOCATION_STATEMENT} from '../../utils/constants.js';

export default class About extends Component {
    render() {
        return (
            <div className="arlo_tm_section" id="about">
                <div className="arlo_tm_about_wrap_all">
                    <div className="arlo_tm_about">
                        <div className="container">
                            <div className="arlo_title_holder">
                                <h3>About <span>Me</span></h3>
                            </div>
                            {this.renderAbout()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderAbout() {
        return (
            <div className="about_inner">
                {this.renderImage()}
                <div className="rightbox wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0.2s">
                    {this.renderTitle()}
                    {this.renderText()}
                    {this.renderAboutContent()}
                    {this.renderButtons()}
                </div>
            </div>
        )
    }

    renderImage() {
        return (
            <div className="leftbox wow fadeInLeft" data-wow-duration="0.8s">
                <div className="about_image_wrap parallax" data-relative-input="true">
                    <div className="image layer" data-depth="0.1">
                        <img src={Black_550_650} alt="" />
                        <div className="inner" data-img-url={About_Me}></div>
                    </div>
                    <div className="border layer" data-depth="0.2" style={{border: 'none'}}>
                        <img src={Black_550_650} alt="" />
                        <div className="inner"></div>
                    </div>
                </div>
            </div>
        )
    }

    renderTitle() {
        return (
            <div className="about_title">
                <h3>I'm <span className="arlo_tm_animation_text_word"></span></h3>
            </div>
        )
    }

    renderText() {
        return (
            <div className="text">
                <p>Hi! My name is <span>Shlok Gondalia</span>. Software Development Engineer at @Sunpower. Previously, I was at Amazon working as a Security Software Engineer.</p>
            </div>
        )
    }

    renderAboutContent() {
        return (
            <div className="about_short_contact_wrap">
                <ul>
                    {this.withHref("fas fa-university", "University:", "https://www.colostate.edu/", "Colorado State University")}
                    {this.withHref("fas fa-atlas", "Major:", "https://compsci.colostate.edu/", "Computer Science")}
                    {this.withHref("fas fa-book", "Minor:", "https://mathematics.colostate.edu/", "Mathematics")}
                    {this.withHref("far fa-envelope", "Mail:", mail(MAIL_STATEMENT), MAIL_STATEMENT)}
                    {this.withOutHref("far fa-calendar-alt", "Graduation Year:", GRADUATION_YEAR_STATEMENT)}
                    {this.withHref("fas fa-mobile", "Phone:", PHONE_LINK_STATEMENT, PHONE_STATEMENT)}
                    {this.withOutHref("fas fa-thumbs-up", "Interests:", INTERESTS_STATEMENT)}
                    {this.location("fas fa-map-marked-alt", "Location:", LOCATION_STATEMENT)}
                </ul>
            </div>
        )
    }

    renderButtons() {
        return (
            <div className="buttons">
                <ul style={{textAlign: "center"}}>
                    {this.buttons(RESUME_LINK, "fas fa-download fa-1x", "Resume")}
                    {this.buttons(LINKEDIN_LINK, "fab fa-linkedin fa-1x", "Linkedin")}
                    {this.buttons(GITHUB_LINK, "fab fa-github fa-1x", "GitHub")}
                </ul>
            </div>
        )
    }

    withHref(className, label, href, title) {
        return (
            <li>
                <i className={className} style={{minWidth: "25px"}}></i>
                <span><label>{label}</label><a href={href} target="_blank" rel="noreferrer">{title}</a></span>
            </li>
        )
    }

    withOutHref(className, label, title) {
        return (
            <li>
                <i className={className} style={{minWidth: "25px"}}></i>
                <span><label>{label}</label>{title}</span>
            </li>
        )
    }

    location(className, label, address) {
        return(
            <li>
                <i className={className} style={{minWidth: "25px"}}></i>
                <span><label>{label}</label><a className="href_location" href="/#">{address}</a></span>
            </li>
        )
    }

    buttons(href, className, title) {
        return (
            <li>
                <div className="about_buttons inner">
                    <a href={href} target="_blank" rel="noreferrer"><span><i className={className}></i> {title}</span></a>
                </div>
            </li>
        )
    }
}