import React, {Component} from 'react';
import ProjectModal from './ProjectModal';
import OwlCarousel from 'react-owl-carousel-autoheight';
import ProjectsResponsiveJSON from "./Projects_Responsive.json";
import * as CONSTANTS from '../../utils/constants';

export default class Projects extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            slideNumber: 0, 
            isInfoOpen: false,
            projects: [
                ["React Personal Website", "Personal Project", "Winter Break (Dec 2020)", CONSTANTS.PJ1_ICONSLIST, CONSTANTS.PJ1_PARA, CONSTANTS.PJ1_FIRSTBUTTON, CONSTANTS.PJ1_SECONDBUTTON],
                ["Trip Planner", "Simplified Version of Google Maps", "CS314", CONSTANTS.PJ2_ICONSLIST, CONSTANTS.PJ2_PARA, CONSTANTS.PJ2_FIRSTBUTTON, CONSTANTS.PJ2_SECONDBUTTON],
                ["Multiplayer Chess Game", "Class Project", "CS414", CONSTANTS.PJ6_ICONSLIST, CONSTANTS.PJ6_PARA, CONSTANTS.PJ6_FIRSTBUTTON, CONSTANTS.PJ6_SECONDBUTTON],
                ["Old Personal Website", "Personal Project", "Winter Break (Dec 2019)", CONSTANTS.PJ3_ICONSLIST, CONSTANTS.PJ3_PARA, CONSTANTS.PJ3_FIRSTBUTTON, CONSTANTS.PJ3_SECONDBUTTON],
                ["Bootstrap Website", "Honors Project", "HONR-292B", CONSTANTS.PJ4_ICONSLIST, CONSTANTS.PJ4_PARA, CONSTANTS.PJ4_FIRSTBUTTON, CONSTANTS.PJ4_SECONDBUTTON],
                ["HTML/CSS Website", "Web Design Project", "JTC-372", CONSTANTS.PJ5_ICONSLIST, CONSTANTS.PJ5_PARA, CONSTANTS.PJ5_FIRSTBUTTON, CONSTANTS.PJ5_SECONDBUTTON]
            ]
        };
        this.updateSlideNumber = this.updateSlideNumber.bind(this);
        this.onClickInfo = this.onClickInfo.bind(this);
    }

    renderInfo(index){
        return (
            <ProjectModal
                index={index}
                isInfoOpen={this.state.isInfoOpen}
                toggleInfoOpen={(isInfoOpen = !this.state.isInfoOpen) => this.setState({isInfoOpen: isInfoOpen})}
                projects={this.state.projects}
            />
        );
    }

    render() {
        return (
            <div className="arlo_tm_section" id="projects">
                <div className="arlo_tm_services">
                    <div className="container">
                        <div className="arlo_title_holder">
                            <h3><span>Projects</span></h3>
                        </div>
                        <div className="project_inner">
                            <ul>
                                <OwlCarousel startPosition={this.state.slideNumber} autoplaySpeed={1000} autoplay={!this.state.isInfoOpen} autoplayHoverPause responsive={ProjectsResponsiveJSON} rewind items={1} center className="owl-theme" nav navText={["<i style='width: 75px;' class='arrow-left fas fa-angle-double-left fa-2x'></i>", "<i style='width: 75px;' class='arrow-right fas fa-angle-double-right fa-2x'></i>"]}>
                                    {
                                        this.state.projects.map((project, index) => {
                                            return (
                                                this.project(index, project[0], project[1], project[2], project[3])
                                            )                
                                        })
                                    }
                                </OwlCarousel>
                            </ul>
                        </div>
                        {this.renderInfo(this.state.index)}
                    </div>
                </div>
            </div>
        )
    }

    project(index, name, title1, title2, iconsList) {
        return (
            <li key={index} className="wow fadeInLeft" data-wow-duration="0.8s">
                <div className="inner">
                        <div className="number">
                            <span>{name}</span>
                        </div>
                        <div className="title">
                            <h5>{title1}</h5>
                            <span>{title2}</span>
                        </div>
                        <div style={{textAlign: "center"}}>
                            {this.icons(iconsList)}
                        </div>  
                        <div className="gitpread_more gitpbuttons" style={{textAlign: "center"}}>
                            <a onClick={(e) => {e.preventDefault(); this.onClickInfo(index)}} href="#projects"><span><i className="fas fa-external-link-square-alt fa-1x"></i> More Info</span></a>
                        </div>
                </div>
            </li>
        )
    }

    updateSlideNumber(e){
        if(e.page.index !== this.state.slideNumber){
            this.setState({slideNumber: e.page.index});
        }
    }

    onClickInfo(index){
        this.setState({index: index, isInfoOpen: true, slideNumber: index});
        return false;
    }

    icons(iconsList) {
        let iconsListKeys = Array.from(Object.keys(iconsList));
        return (
            <div className="text">
                {iconsListKeys.map((icon, index) => {
                    return (
                        <i key={index} className={iconsList[icon]} title={icon} style={{marginRight: "20px", marginBottom: "10px", color: "white", width: "40px", textAlign: "center", borderRadius: "5px", verticalAlign: "middle", lineHeight: "40px"}}></i>
                    )
                })}
            </div>
        )
    }
}