import React, {Component} from 'react';
import Black_Logo from '../static/images/Black_Logo.png'
import {Spin as Hamburger} from 'hamburger-react'

export default class TopBar extends Component {

	constructor(props) {
		super(props);
		this.dropDownToggle = React.createRef();
		this.setDropDown = this.setDropDown.bind(this);
	}

    render() {
        return(
            <div className="arlo_tm_topbar">
				<div className="topbar_inner">
					<div className="container">
						<div className="topbar_in">
							<div className="logo">
								<a className="icon-scroll" href="#home"><img src={Black_Logo} alt="" height="63" width="35"/></a>
							</div>
							<div className="trigger">
								<Hamburger size={45} rounded={true} color="black" onToggle={() => this.setDropDown()}></Hamburger>
							</div>
							<div ref={this.dropDownToggle} className="trigger2" style={{display: "none"}}></div>
						</div>
					</div>
				</div>
				{this.renderDropDown()}
			</div>
        )
	}

	setDropDown(){
		this.dropDownToggle.current.click();
	}
	
	renderDropDown(){
		return(
			<div className="dropdown">
				<div className="container">
					<div className="dropdown_inner">
						<ul>
							{this.dropDownItem("#home", "fas fa-home fa-1x", "Home")}
							{this.dropDownItem("#about", "fas fa-user-tie fa-1x", "About")}
							{this.dropDownItem("#skills", "fas fa-laptop-code fa-1x", "Skills")}
							{this.dropDownItem("#experiences", "fas fa-business-time fa-1x", "Experiences")}
							{this.dropDownItem("#courses", "fas fa-university fa-1x", "Courses Taken")}
							{this.dropDownItem("#projects", "fas fa-project-diagram fa-1x", "Projects")}
							{this.dropDownItem("#gpa", "fas fa-chart-line fa-1x", "GPA")}
							{this.dropDownItem("#awards", "fas fa-award fa-1x", "Awards")}
							{this.dropDownItem("#stats", "fas fa-poll-h fa-1x", "Interesting Stats")}
							{this.dropDownItem("#contact", "fas fa-id-badge fa-1x", "Contact")}
						</ul>
					</div>
				</div>
			</div>
		)
	}

    dropDownItem(href, className, title){
        return(
            <li><a href={href}><i className={className} style={{minWidth: "30px"}}></i>{title}</a></li>
        )
	}
}