import React, {Component} from 'react';
import Black_Logo from '../static/images/Black_Logo.png'
import {mail} from './CommonFunctions'
import {MAIL_STATEMENT, LINKEDIN_LINK, HANDSHAKE_LINK, GITHUB_LINK, FACEBOOK_LINK, INSTAGRAM_LINK} from '../utils/constants.js';


export default class LeftPart extends Component {
    render() {
        return (
            <div className="arlo_tm_leftpart">
				<div className="inner">
					{this.renderLogo()}
                    {this.renderMenu()}
                    {this.renderBottomButtons()}
					{this.renderResize()}
				</div>
			</div>
        )
    }

    renderLogo() {
        return (
            <div className="logo">
                <a className="icon-scroll" href="#home"><img src={Black_Logo} style={{marginLeft: "70%", marginRight: "70%"}} alt="" /></a>
            </div>
        )
    }

    renderMenu() {
        return (
            <div className="menu scrollable">
                <ul>
                    {this.menuScrollable("#home", "fas fa-home fa-1x", "Home")}
                    {this.menuScrollable("#about", "fas fa-user-tie fa-1x", "About")}
                    {this.menuScrollable("#skills", "fas fa-laptop-code fa-1x", "Skills")}
                    {this.menuScrollable("#experiences", "fas fa-business-time fa-1x", "Experiences")}
                    {this.menuScrollable("#courses", "fas fa-university fa-1x", "Courses Taken")}
                    {this.menuScrollable("#projects", "fas fa-project-diagram fa-1x", "Projects")}
                    {this.menuScrollable("#gpa", "fas fa-chart-line fa-1x", "GPA")}
                    {this.menuScrollable("#awards", "fas fa-award fa-1x", "Awards")}
                    {this.menuScrollable("#stats", "fas fa-poll-h fa-1x", "Interesting Stats")}
                    {this.menuScrollable("#contact", "fas fa-id-badge fa-1x", "Contact")}
                </ul>
            </div>
        )
    }

    renderBottomButtons() {
        return (
            <div className="bottom">
                <div className="social">
                    <ul>
                        {this.bottomButton(LINKEDIN_LINK, "Linkedin", "fab fa-linkedin-in fa-2x")}
                        {this.bottomButton(HANDSHAKE_LINK, "Handshake", "fas fa-handshake fa-2x")}
                        {this.bottomButton(GITHUB_LINK, "GitHub", "fab fa-github fa-2x")}
                        {this.bottomButton(mail(MAIL_STATEMENT), "Mail", "far fa-envelope fa-2x")}
                        {this.bottomButton(FACEBOOK_LINK, "Facebook", "fab fa-facebook-f fa-2x")}
                        {this.bottomButton(INSTAGRAM_LINK, "Instagram", "fab fa-instagram fa-2x")}
                    </ul>
                </div>
            </div>
        )
    }

    renderResize() {
        return (
            <div className="resize">
                <a href="/#"><span></span></a>
            </div>
        )
    }

    menuScrollable(href, className, title) {
        return (
            <li><a href={href}><i className={className} style={{minWidth: "50px"}}></i>{title}</a></li>
        )
    }

    bottomButton(href, title, className) {
        return (
            <li><a href={href} target="_blank" rel="noreferrer" title={title}><i className={className}></i></a></li>
        )
    }
}

