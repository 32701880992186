import React, {Component} from 'react';
import Languages from './Languages';
import Technologies from './Technologies';
import Tools from './Tools';

export default class Skills extends Component {

    render() {
        return(
            <div className="arlo_tm_section" id="skills">
                <div className="arlo_tm_services">
                    <div className="container">
                        <div className="arlo_title_holder" style={{marginTop: "0px"}}>
                            <h3><span>Skills</span></h3>
                        </div>
                        <div className="service_inner">
                            <ul>
                                <Languages/>
                                <Technologies/>
                                <Tools/>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}